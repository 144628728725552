.menu-mobile {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(to right,#23b5f9, #0e89f5 );
    padding: 40px;
    color: white;
    overflow-y: scroll;
}

.divider {
    width: 100%;
    border: none;
    border-top: 1px solid #33ffffff;
    opacity: 0.3;
}

.menu-mobile ul {
    list-style-type: none;
    padding: 0;
    margin-bottom: 80px;
}
.menu-mobile ul li + li {
    margin-top: 40px;
}
.menu-mobile p {
    margin: 40px 0 24px;
}
.menu-mobile .googleplay-logo {
    margin-bottom: 40px;
}
.menu-mobile .menu-mobile-footer {
    margin-top: 80px;
    display: flex;
    flex-direction: row;
    gap: 16px;
}
.menu-mobile-footer a, .menu-mobile a{
    color: white;
    text-decoration: none;
}

.logo-container{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.logo-container img{
    height: 88px;
}

.logo-container .button-close {
    margin-left: auto;
    height: 168px;
}

.button-menu {
    display: none;
}

@media screen and (max-width: 992px) {
    .button-menu {
        display: block;
        position: fixed;
        top: 0;
        right: 0;
        width: 180px;
    }
}

@media screen and (max-width: 576px) {
    .logo {
        width: 244px;
    }

    .logo-container img{
        height: 48px;
    }
    
    .logo-container .button-close {
        margin-left: auto;
        height: 88px;
    }

    .menu-mobile {
        padding: 24px 24px 106px;
        text-align: center;
    }

    .menu-mobile ul {
        margin-bottom: 48px;
        margin-top: 24px;
    }

    .menu-mobile ul li + li {
        margin-top: 32px;
    }

    .menu-mobile .menu-mobile-footer {
        margin-top: 48px;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .button-menu {
        width: 88px;
    }
}