footer {
    padding: 40px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
}
footer .link-mobile {
    display: none;
}
.copyright {
    margin-top: 16px;
}

@media screen and (max-width: 576px) {
    footer .link {
        display: none;
    }

    footer .link-mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;
    }

    .copyright {
        margin-top: 32px;
    }
}