.home-wrapper {
    width: 100vw;
    overflow-y: scroll;
    overflow-x: hidden;
}
.top-wrapper {
    background-image: url("../assets/oval.png");
    background-repeat: round;
    background-size: contain;
}

.menu-top {
    display: flex;
}
.menu-top .menu{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: auto;
    margin-bottom: 0;
    padding: 40px 40px 0;
    gap: 25px;
    
    list-style-type: none;
}
.menu .list-item a{
    text-decoration: none;
    color: #182a39;
}
.list-item {
    position: relative;
}
.logo-google {
    margin-left: 15px;
}
.active::after {
    content: "";
    height: 2px;
    background-image: linear-gradient(to right,#23b5f9, #0e89f5);
    position: absolute;
    bottom: -4px;
    left: 0;
    right: 0;
}

.mobile-intro-text {
    display: none;
}

.top-content {
    padding: 152px 24px;
    justify-content: center;
}
.pairla-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
}
.pairla-list-content {
    display: flex;
    flex-direction: row;
}
.pairla-list-content .item-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.item-wrapper img {
    max-width: 156px;
    width: 100%;
    object-fit: scale-down;
}
.logo {
    max-width: 400px;
    max-height: 129px;
    width: 100%;
    height: 100%;
}

.image-container {
    max-width: 370px;
    height: auto;
}

/* about section */

.about-wrapper {
    background-image: linear-gradient(to right, #23b5f9, #0e89f5);
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 120px 24px;
    font-size: 16px;
}
.about-wrapper h2 {
    margin-bottom: 24px;
}
.intro-app-text {
    font-size: 14px;
    margin: 24px 0 40px;
}
.about-content {
    max-width: 720px;
    padding: 40px 0;
    width: 100%;
    background-image: linear-gradient(to right, #23b5f9, #0e89f5);
    border-radius: 20px;
    box-shadow: 8px 16px 32px 0 #19000024;

    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
}
.about-content .app-icon {
    width: 128px;
}
.divider {
    width: 100%;
    border: none;
    border-top: 1px solid #33ffffff;
    opacity: 0.3;
}
.notice-text {
    color: #fcff00;
    margin: 24px 0 40px;
}

/* more section */

.more-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 120px 24px;
}
.more-wrapper .section-title {
    margin-bottom: 120px;
}
.reverse{
    flex-direction: row-reverse;    
}
.more-content span {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;

    max-width: 400px;
}

/* term section */

.term-wrapper {
    background-color: #c3d1dc;
    display: flex;
    justify-content: center;
    padding: 120px 24px;
}
.term-wrapper .about-content {
    background-color: #e6ecf1;
    background-image: none;
}
.term-wrapper .divider {
    border-color: #d0d4d9;
    opacity: 1;
}
.term-wrapper .notice-text {
    color: #ff589c;
}

/* menu mobile */

.menu-mobile {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(to right,#23b5f9, #0e89f5 );
    padding: 40px;
    color: white;
    overflow-y: scroll;
}

.menu-mobile ul {
    list-style-type: none;
    padding: 0;
    margin-bottom: 80px;
}
.menu-mobile ul li + li {
    margin-top: 40px;
}
.menu-mobile p {
    margin: 40px 0 24px;
}
.menu-mobile .googleplay-logo {
    margin-bottom: 40px;
}
.menu-mobile .menu-mobile-footer {
    margin-top: 80px;
    display: flex;
    flex-direction: row;
    gap: 16px;
}
.menu-mobile-footer a{
    color: white;
    text-decoration: none;
}

.logo-container{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.logo-container img{
    height: 88px;
}

.logo-container .button-close {
    margin-left: auto;
    height: 168px;
}

.button-menu {
    display: none;
}

.hyperlink-text {
    text-decoration: none;
    color: #0e89f5;
}

.arrow-down {
    width: 80px;
    position: fixed;
    bottom: 40px;
    right: 40px;
    cursor: pointer;
}

@media screen and (max-width: 992px) {
    .menu-top {
        display: none;
    }
    .button-menu {
        display: block;
        position: fixed;
        top: 0;
        right: 0;
        width: 180px;
    }
    .top-wrapper {
        background-image: url("../assets/background_mobile.png");
        background-size: contain;
        background-repeat: round;
    }
    .arrow-down {
        display: none;
    }
}

@media screen and (max-width: 576px) {
    .logo {
        width: 244px;
    }

    .logo-container img{
        height: 48px;
    }
    
    .logo-container .button-close {
        margin-left: auto;
        height: 88px;
    }

    .menu-mobile {
        padding: 24px 24px 106px;
        text-align: center;
    }

    .menu-mobile ul {
        margin-bottom: 48px;
        margin-top: 24px;
    }

    .menu-mobile ul li + li {
        margin-top: 32px;
    }

    .menu-mobile .menu-mobile-footer {
        margin-top: 48px;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .button-menu {
        width: 88px;
    }

    .mobile-intro-text {
        display: block;
        text-align: center;
        font-size: 14px;
    }

    .intro-text {
        display: none;
    }

    .item-wrapper p {
        font-size: 12px;
    }
}