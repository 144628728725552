.notice-container {
    background-color: #f6f6f6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 80px;
}

.notice-container h1 {
    text-align: center;
    font-size: 30px;
    margin-bottom: 80px;
}

.notice-container .notice-content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.notice-content-wrapper div{
    display: flex;
    flex-direction: column;
    text-align: left;
}

.notice-content-wrapper ul, .notice-content-wrapper ol{
    padding-left: 20px;
}

.notice-content {
    width: 100%;
}

.notice-content-mobile {
    display: none;
    width: 100%;
}

@media screen and (max-width: 992px) {
    .notice-container {
        padding: 80px 0;
    }
}

@media screen and (max-width: 576px) {
    .notice-container {
        padding: 40px 0;
    }
    .notice-container h1 {
        padding: 0 16px;
    }
    .notice-content {
        display: none;
    }
    .notice-content-mobile {
        display: block;
    }
}