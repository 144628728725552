.header {
    display: flex;
    justify-content: center;
    background-color: white;
    padding: 32px 120px;
}

.header img {
    height: 80px;
    object-fit: contain;
    cursor: pointer;
}

@media screen and (max-width: 992px) {
    .header img {
        height: 54px;
    }
}

@media screen and (max-width: 576px) {
    .header img {
        height: 48px;
    }
}